// External Dependencies
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import React from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import CurveSection from '../components/shared/CurveSection';
import HeaderCurve from '../components/HeaderCurve';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Component definition
const Promotion = () => {
  return (
    <Layout variant="curve">
      <Meta title="Promotion" />

      <HeaderCurve
        subtitle="Our promotion is over, but you can still sign up for a free trial!"
        title="Promotion Ended"
      />

      <div className="switch" id="main">
        <CurveSection
          className="main"
          id="content"
        >
          <Container maxWidth="md">
            <Card
              className="switch-card"
              data-variant="outlined"
              variant="outlined"
            >
              <CardContent className="align-left">
                <Typography paragraph>
                  Even though our promotion has ended, you can
                  still sign up for a free trial of Presto Assistant.
                </Typography>

                <Typography paragraph>
                  Sign up today for a fresh start for your fine arts program!
                </Typography>
              </CardContent>
            </Card>
          </Container>

          <Box className="panel">
            <a
              className="transparent-button"
              data-variant="transport"
              href="https://www.presto-assistant.com/trial"
            >
              Get Started
            </a>
          </Box>
        </CurveSection>
      </div>
    </Layout>
  );
};

export default Promotion;
